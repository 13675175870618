// ==============================|| OVERRIDES - BADGE ||============================== //

export default function Alert() {
    return {
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    backgroundColor: '#2a319b',
                    color: '#fff',
                },
                icon: {
                    color: '#ffffff !important',
                },
            },
        },
    };
}
