import { App } from './components/app';
import { h, render } from 'preact';
import { shadowRootElement } from './themes';

// app shadow root

const appRoot = document.querySelector('#photon-chat-widget');
appRoot.attachShadow({
    mode: 'open',
});

render(<App />, shadowRootElement);

// // render into some specific element:
// if(document)
// render(<App />, document.getElementById('photon-chat-widget'))
