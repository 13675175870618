import { UtilsService } from './utils.service';

export enum NetworkRequestMethod {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export interface INetworkRequestProps {
    method: NetworkRequestMethod;
    headers?: { [key: string]: string };
    url: string;
    body?: any;
    retryCount?: number;
    callback?: (data: string) => void;
}

export interface INetworkResponse {
    status: number;
    success: boolean;
    error: any;
}

export const useNetworkAPI = () => {
    const triggerRequest = async (
        requestProps: INetworkRequestProps,
        headers?: string[],
        skipAuthHeaders?: boolean,
        redirectToLoginOnFailure?: boolean
    ): Promise<any> => {
        try {
            if (requestProps.retryCount === undefined) {
                requestProps.retryCount = 3;
            }
            if (!requestProps.headers) {
                requestProps.headers = {};
            }

            let res: Response = await fetch(requestProps.url, {
                method: requestProps.method.toString(),
                headers: requestProps.headers,
                body: requestProps.body,
            });
            let respHeaders: { [key: string]: string } = {};
            headers?.forEach((h: string) => {
                respHeaders[h] = res.headers.get(h) as string;
            });

            let body = await res.json();

            if (res.status == 401 && skipAuthHeaders) {
                return { body, headers: respHeaders };
            }

            if ([401].includes(res.status) && redirectToLoginOnFailure) {
                // navigate('/login');
                return { body, headers: respHeaders };
            }

            // if (res.status == 401) {
            //     let tokenGenerated = await generateAccessToken(triggerRequest);
            //     if (tokenGenerated) {
            //         return await triggerRequest(
            //             requestProps,
            //             headers,
            //             skipAuthHeaders,
            //             redirectToLoginOnFailure
            //         );
            //     } else {
            //         return { body, headers: respHeaders };
            //     }
            // }

            if (res.status === 500) {
                return body;
            }
            return { body, headers: respHeaders, status: res.status };
        } catch (error) {
            if (
                requestProps.retryCount !== undefined &&
                requestProps.retryCount > 0
            ) {
                requestProps.retryCount--;
                return await triggerRequest(
                    requestProps,
                    headers,
                    skipAuthHeaders,
                    redirectToLoginOnFailure
                );
            }
            throw error;
        }
    };

    const triggerRequestWithChunkedResponse = async (
        requestProps: INetworkRequestProps,
        headers?: string[],
        skipAuthHeaders?: boolean,
        redirectToLoginOnFailure?: boolean
    ): Promise<any> => {
        try {
            // WARNING: For POST requests, body is set to null by browsers.

            var xhr = new XMLHttpRequest();

            xhr.addEventListener('readystatechange', function () {
                if (requestProps.callback) {
                    requestProps.callback(this.responseText);
                }
                if (this.readyState === 4) {
                    return this.responseText;
                }
                const status = this.status;
                if (!(status === 0 || (status >= 200 && status < 400))) {
                    console.error(this.responseText);
                }
            });

            xhr.open('POST', requestProps.url);
            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.send(requestProps.body);
        } catch (error) {
            if (
                requestProps.retryCount !== undefined &&
                requestProps.retryCount > 0
            ) {
                requestProps.retryCount--;
                return await triggerRequestWithChunkedResponse(
                    requestProps,
                    headers
                );
            }
            throw error;
        }
    };
    return {
        triggerRequest,
        triggerRequestWithChunkedResponse,
    };
};
