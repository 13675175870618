import { shadowRootElement } from '../';
export default function Tooltip() {
    return {
        MuiTooltip: {
            defaultProps: {
                container: shadowRootElement,
            },
            styleOverrides: {
                tooltip: {
                    fontSize: '13px',
                    color: '#ffffff',
                    backgroundColor: '#4B4B4C',
                    border: '1px solid #4B4B4C',
                    borderRadius: '4px',
                    maxWidth: '500px',
                },
                arrow: {
                    '&:before': {
                        border: '1px solid #4B4B4C',
                    },
                    color: '#4B4B4C',
                },
            },
        },
    };
}
