// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (fontFamily: string) => ({
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '40px',
    },
    h2: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '40px',
    },
    h3: {
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '40px',
    },
    h4: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '32px',
    },
    h5: {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: 1.5,
    },
    h6: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
    },
    caption: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
    },
    body1: {
        fontSize: '0.875rem',
        lineHeight: 1.57,
    },
    body2: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
    },
    subtitle1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    subtitle2: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
    },
    overline: {
        lineHeight: 1.66,
    },
    button: {
        textTransform: 'capitalize',
    },
});

export default Typography;
