import { IChatbotConfig, IChatbotConfigData } from 'src/services/interfaces';

const {
    PREACT_APP_USER_API_HOST,
    PREACT_APP_INSIGHT_API_HOST,
    PREACT_APP_CUSTOM_INSIGHT_API_HOST,
    PREACT_APP_WIDGET_API_HOST,
} = {
    PREACT_APP_USER_API_HOST: 'https://prod-gateway.photoninsights.com/user/',
    PREACT_APP_WIDGET_API_HOST:
        'https://prod-gateway.photoninsights.com/chatbot/',
    PREACT_APP_INSIGHT_API_HOST:
        'https://prod-gateway.photoninsights.com/insight/',
    PREACT_APP_CUSTOM_INSIGHT_API_HOST:
        'https://prod-gateway.photoninsights.com/custom-insights/',
};

const PAGE_SIZE = 10;

export const Constants = {
    documentQuery: `${PREACT_APP_WIDGET_API_HOST}api/v1/public/widget/answer`,
    updateDocumentChat: `${PREACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/chatbot/answer`,
    updateSessionUser: `${PREACT_APP_CUSTOM_INSIGHT_API_HOST}api/v1/public/chatbot/update-session-user`,
    submitVisitorDetails: `${PREACT_APP_WIDGET_API_HOST}api/v1/public/widget/fill-visitor-information`,
    getWidgetDetails: `${PREACT_APP_WIDGET_API_HOST}api/v1/public/widget`,
};
