import { useEffect, useState } from 'preact/hooks';
import { Citation } from '../citation';
import { ChatMessage, IncomingMessageWrapper } from './chat-dialog';
import { alpha, styled } from '@mui/material/styles';
import { ResponseSources } from './response-sources';
import { h } from 'preact';
import {
    IChatbotAppearance,
    IChatbotConfig,
    IMessage,
    IChatbotConfigData,
} from 'src/services/interfaces';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputBase,
    InputLabel,
    Typography,
} from '@mui/material';
import { useChatbotService } from '../../../services/chatbot.service';
import { UtilsService } from '../../../services/utils.service';

interface IProps {
    chatbotConfig: IChatbotConfig;
    onCancel: () => void;
    onSuccess: (widgetSessionChatId: string) => void;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(4),
        fontSize: 12,
        fontWeight: 400,
        color: '#525252',
    },
    '&.Mui-error .MuiInputBase-input': {
        borderColor: '#DA1E28 !important',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: '200',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

export const DataCollectionForm = (props: IProps) => {
    const { visitorInfo } = props.chatbotConfig;
    const [processing, setProcessing] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const { submitVisitorDetails } = useChatbotService();
    const [checkError, setCheckError] = useState(false);
    const [formData, setFormData] = useState({
        senderEmailId: '',
        metadata: {},
    });
    const updateFieldValue = (key: string, value: string) => {
        setCheckError(false);
        if (key == 'Email') {
            setFormData({
                ...formData,
                senderEmailId: value,
            });
        } else {
            setFormData({
                ...formData,
                metadata: {
                    ...formData.metadata,
                    [key]: value,
                },
            });
        }
    };

    const onSubmitClick = async () => {
        setCheckError(true);
        if (!formData.senderEmailId||!UtilsService.isValidEmail(formData.senderEmailId)) {
            return;
        }
        if (
            visitorInfo.optionalFields &&
            visitorInfo.optionalFields.filter(
                (f) => f.required && !formData.metadata[f.title]
            ).length > 0
        ) {
            return;
        }
        setProcessing(true);
        console.log(formData);
        let res = await submitVisitorDetails(formData);
        
        setProcessing(false);
        setSubmitted(true);
        console.log(res);
        props.onSuccess(res.widgetSessionChatId);
    };

    useEffect(()=>{
        setSubmitted(false);
        setCancelled(false);
        setCheckError(false);
        setFormData({
            senderEmailId: '',
            metadata: {},
        })
    },[props.chatbotConfig])

    return (
        <IncomingMessageWrapper sx={{ width: '70% !important' }}>
            {submitted && (
                <ChatMessage
                    sx={{
                        fontSize:
                            props.chatbotConfig.designConfig.data.config.appearance
                                .font.fontSize,
                    }}
                >
                    <Citation
                        onCitationClick={() => {}}
                        text={'Thanks for submitting your details.'}
                        disableCitation={!props.chatbotConfig.withCitations}
                    />
                </ChatMessage>
            )}
            {cancelled && (
                <ChatMessage
                    sx={{
                        fontSize:
                            props.chatbotConfig.designConfig.data.config.appearance
                                .font.fontSize,
                    }}
                >
                    <Citation
                        onCitationClick={() => {}}
                        text={
                            'Your form submission has been canceled, please let us know if you need any assistance in the future!'
                        }
                        disableCitation={!props.chatbotConfig.withCitations}
                    />
                </ChatMessage>
            )}
            {(!submitted&&!cancelled) && (
                <Box>
                    <Typography>{visitorInfo?.message}</Typography>
                    {visitorInfo.mendatoryFields.map((f) => (
                        <Box py={1}>
                            <FormControl
                                sx={{ width: '100%' }}
                                variant="standard"
                            >
                                <InputLabel
                                    pb={1}
                                    shrink
                                    htmlFor="bootstrap-input"
                                >
                                    {f.title}
                                    {f.required ? '*' : ''}
                                </InputLabel>
                                <BootstrapInput
                                value={formData.senderEmailId}
                                    onChange={(e) =>
                                        updateFieldValue(
                                            f.title,
                                            //@ts-ignore
                                            e.target.value
                                        )
                                    }
                                    error={
                                        checkError && !UtilsService.isValidEmail(formData.senderEmailId)
                                    }
                                />
                            </FormControl>
                            {checkError && !UtilsService.isValidEmail(formData.senderEmailId) && (
                                <Typography fontSize={12} color="error">
                                    Please enter valid {f.title}
                                </Typography>
                            )}
                        </Box>
                    ))}
                    {visitorInfo.optionalFields?.map((f) => (
                        <Box py={1}>
                            <FormControl
                                sx={{ width: '100%' }}
                                variant="standard"
                            >
                                <InputLabel
                                    pb={1}
                                    shrink
                                    htmlFor="bootstrap-input"
                                >
                                    {f.title}
                                    {f.required ? '*' : ''}
                                </InputLabel>
                                <BootstrapInput
                                value={formData.metadata[f.title]||''}
                                    error={
                                        checkError &&
                                        f.required &&
                                        !formData.metadata[f.title]
                                    }
                                    onChange={(e) =>
                                        //@ts-ignore
                                        updateFieldValue(
                                            f.title,
                                            //@ts-ignore
                                            e.target.value
                                        )
                                    }
                                />
                            </FormControl>
                            {checkError &&
                                f.required &&
                                !formData.metadata[f.title] && (
                                    <Typography fontSize={12} color="error">
                                        Please enter {f.title}
                                    </Typography>
                                )}
                        </Box>
                    ))}
                    <Box display="flex" justifyContent={'space-between'} mt={2}>
                        <Box></Box>
                        <Box>
                            {!visitorInfo.isInfoRequired && (
                                <Button
                                    onClick={() => {
                                        setCancelled(true);
                                        props.onCancel();
                                    }}
                                    mr={2}
                                    variant="text"
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                ml={2}
                                onClick={onSubmitClick}
                                variant="contained"
                            >
                                {processing ? (
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: '#ffffff',
                                        }}
                                    />
                                ) : visitorInfo.isInfoRequired ? (
                                    'Start the Chat'
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </IncomingMessageWrapper>
    );
};
