// ==============================|| OVERRIDES - LINK ||============================== //
import { shadowRootElement } from '../';
export default function Box() {
    return {
        MuiBox: {
            defaultProps: {
                container: shadowRootElement,
            },
        },
    };
}
