import { h } from 'preact';
interface IProps {
    mt: number;
    mb?: number;
    h: number;
    mx?: number;
    w: number;
    color?: string;
}

export const Circle = (props: IProps) => {
    return (
        <svg
            style={{
                marginTop: props.mt,
                marginBottom: props.mb || 0,
                marginLeft: props.mx,
                marginRight: props.mx,
            }}
            width={props.h}
            height={props.w}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="6" fill={props.color || '#A5A6AB'} />
        </svg>
    );
};
