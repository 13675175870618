import { Box } from '@mui/material';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

interface IProps {
    text: string;
    disableCitation?: boolean;
    onCitationClick: (index: number) => void;
}

export const Citation = (props: IProps) => {
    const [texts, setTexts] = useState(props.text.split('\n'));
    useEffect(() => {
        let processedMessages = [];
        let messages = props.text.split('\n');
        for (let message of messages) {
            for (let i = 0; i < 10; i++) {
                message = message.split(`[${i}]`).join(
                    `<span citationId="${i}" style='margin-left: 2px;
                        position: relative;
                        display:${
                            props.disableCitation ? 'none' : 'inline-block'
                        };
                        font-size: 10px;
                        width: 15px;
                        text-align: center;
                        height: 15px;
                        line-height: 14px;
                        top: -4px;
                        border: 1px solid #a5a6ab;
                        background-color: #a5a6ab;
                        border-radius: 50%;
                        cursor: pointer;
                        color: #fff;'
                        onMouseOver="this.style.backgroundColor='#29319b';this.style.border='1px solid #29319b'"
                        onMouseOut="this.style.backgroundColor='#a5a6ab';this.style.border='1px solid #a5a6ab'">${i}</span>`
                );
            }
            processedMessages.push(message);
        }
        setTexts([...processedMessages]);
    }, [props.text]);

    const handleClick = (event: any) => {
        try {
            let citationId = (event.target as HTMLElement).getAttribute(
                'citationId'
            );
            if (citationId) props.onCitationClick(parseInt(citationId));
        } catch (error) {}
    };

    return (
        <Box>
            {texts.map((text) => (
                <Box
                    sx={{
                        '& p': {
                            margin: '0px !important',
                        },
                    }}
                >
                    <span
                        style={{ display: 'inline-block' }}
                        onClick={handleClick}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                    <br />
                </Box>
            ))}
        </Box>
    );
};
