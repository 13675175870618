import { Constants } from '../constants';
import {
    DocumentInsightQueryRequest,
    INetworkResponse,
    TopicChatUpdateRequest,
    IChatbotConfig,
    IChatbotConfigResponse,
    UpdateChatSessionUserRequest,
    ChatbotUser,
    VisitorInforResponseData,
    IVisitorInforResponse,
} from './interfaces';
import { NetworkRequestMethod, useNetworkAPI } from './network.service';
declare const window: any;
export interface FileUploadStatus {
    progress: number;
    created?: boolean;
}

export const useChatbotService = () => {
    const { triggerRequest, triggerRequestWithChunkedResponse } =
        useNetworkAPI();

    const fetchDocumentInsightsQueryResponse = async (
        question: string,
        widgetSessionChatId: string,
        chatThreadId:string,
        callback: (data: string) => void
    ): Promise<string> => {
        try {
            return new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json',
                };
                const body: DocumentInsightQueryRequest = {
                    query: question,
                    chatThreadId,
                    widgetId: getChatWidgetId(),
                    detectEntitiesFromAnswer: true,
                    stream: true,
                };
                if (widgetSessionChatId) {
                    body.widgetSessionChatId = widgetSessionChatId;
                }
                triggerRequestWithChunkedResponse({
                    method: NetworkRequestMethod.POST,
                    headers,
                    body: JSON.stringify(body),
                    url: Constants.documentQuery,
                    retryCount: 0,
                    callback,
                }).then((data) => {
                    resolve(data);
                });
            });
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const setWidgetSessionUser = async (
        user: ChatbotUser,
        widgetSessionChatId: string
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: UpdateChatSessionUserRequest = {
                senderEmailId: user.email,
                senderUserId: user.id,
                metadata: user.metadata,
                widgetSessionChatId,
                widgetId: getChatWidgetId(),
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: Constants.updateSessionUser,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const submitVisitorDetails = async (
        data:{
            senderEmailId: string;
            metadata: {};
        }
    ): Promise<VisitorInforResponseData> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body = {
                ...data,
                widgetId: getChatWidgetId(),
            };
            const result: {
                body: IVisitorInforResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.POST,
                headers,
                body: JSON.stringify(body),
                url: Constants.submitVisitorDetails,
                retryCount: 3,
            });

            return result.body.data;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };

    const getChatWidgetId = () => {
        if (typeof window.photon.getChatbotId == 'function') {
            return window.photon.getChatbotId();
        }
        return document
            .querySelector('#photon-chat-widget')
            .getAttribute('[photon-chat-widget-id]');
    };

    const fetchChatbotConfig = async (): Promise<IChatbotConfig> => {
        const chatbotId = getChatWidgetId();
        if (!chatbotId||chatbotId=='new') return;
        const headers = {
            'Content-Type': 'application/json',
        };
        const result: {
            body: IChatbotConfigResponse;
            headers: { [key: string]: string };
        } = await triggerRequest({
            method: NetworkRequestMethod.GET,
            headers,
            url: `${Constants.getWidgetDetails}/${chatbotId}`,
            retryCount: 3,
        });

        return result.body.data;
    };

    const updateDocumentInsightChat = async (
        documentChatId: string,
        isBookmarked: boolean,
        vote: 'upvote' | 'downvote' | 'neutral',
        chatBotPrompt: string
    ): Promise<boolean> => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const body: TopicChatUpdateRequest = {
                action: 'UPDATE',
                isBookmarked,
                vote,
                chatBotPrompt,
            };
            const result: {
                body: INetworkResponse;
                headers: { [key: string]: string };
            } = await triggerRequest({
                method: NetworkRequestMethod.PATCH,
                headers,
                body: JSON.stringify(body),
                url: `${Constants.updateDocumentChat}/${documentChatId}`,
                retryCount: 3,
            });

            return result.body.success;
        } catch (error: any) {
            console.error(error);
            return error;
        }
    };
    return {
        fetchDocumentInsightsQueryResponse,
        updateDocumentInsightChat,
        fetchChatbotConfig,
        submitVisitorDetails,
        setWidgetSessionUser,
        getChatWidgetId
    };
};
