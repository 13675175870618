// ==============================|| OVERRIDES - LINK ||============================== //
import { shadowRootElement } from '../';
export default function Modal() {
    return {
        MuiModal: {
            defaultProps: {
                container: shadowRootElement,
            },
            styleOverrides: {
                root: {
                    position: document.getElementById(
                        'photon-chat-widget-container'
                    )
                        ? 'inherit'
                        : 'fixed',
                },
            },
        },
    };
}
